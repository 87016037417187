import React, { Component } from 'react'

// React-DML imports
import { Grid, Cell } from "react-mdl";

export class Results extends Component {
  render() {
    return (
      <div>
        <Grid className="HomePageGrid">
          <Cell col={12}>
            <div className="Profile-title-text">

              <h2 className="Profile-subsubtitle-text">Result</h2>

              <p className="Intro">The neural network achieved a promising accuracy of 87% in classifying PMP practice questions into difficulty levels (Easy, Medium, and Hard). The results highlight strong classification performance for Easy questions, with moderate overlap between Medium and Hard categories. This performance demonstrates the model’s ability to capture textual patterns and contextual nuances, though some refinements could further improve its accuracy. Incorporating additional data and fine-tuning hyperparameters are potential next steps to reduce misclassifications.</p>
              <p className="Intro">The confusion matrix reveals that the model consistently excels in identifying Easy questions, achieving the highest accuracy in this category. However, the distinction between Medium and Hard questions remains a challenge, likely due to the conceptual similarities and linguistic overlap between these categories. While the model's performance for Medium questions is relatively strong, occasional misclassifications to Hard suggest areas for improvement. Future iterations could leverage domain-specific features or adjust embeddings to better differentiate these categories.</p>
              <img src="https://firebasestorage.googleapis.com/v0/b/ketofinder-a8370.appspot.com/o/Result01.png?alt=media&token=d02fb0d3-11a7-4de5-9e91-1c6968e34fa6" alt="Model Architecture " className="pic" />

              <p className="Intro">The ANN leverages BERT embeddings to translate textual data into rich, contextual representations, enabling it to handle complex patterns in the dataset. Despite its small size of 450 questions, the model demonstrates robust performance due to the quality of data preparation and feature extraction. This result highlights the importance of advanced preprocessing steps like embedding extraction, tokenization, and data cleaning, which collectively enhance the model's ability to generalize across difficulty levels.</p>
              <p className="Intro">These results underscore the potential of leveraging neural networks for educational applications, such as PMP preparation. Accurate difficulty classification not only streamlines the learning process for candidates but also enables adaptive practice tools to target user-specific needs. By classifying questions effectively, this system can guide users toward balanced study plans, improving their exam readiness and confidence.</p>
              <p className="Intro">The analysis supports the potential for further enhancements, including dataset expansion and adaptive learning features. Additionally, implementing readiness prediction and adaptive question recommendations could make the application even more valuable for PMP candidates. With the foundation established by the current results, future improvements can build on the system’s strengths to deliver an even more personalized and impactful learning experience.</p>


            </div>
          </Cell>

        </Grid>
      </div>

    )
  }
}


export default Results