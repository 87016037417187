import React, { Component } from 'react'

// React-DML imports
import { Grid, Cell } from "react-mdl";

export class Analysis extends Component {
  render() {
    return (
      <div>
        <Grid className="HomePageGrid">
          <Cell col={12}>
            <div className="Profile-title-text">

              <h2 className="Profile-subsubtitle-text">Model / Method used</h2>

              <p className="Intro">The core architecture for this project is an Artificial Neural Network (ANN) designed for multi-class classification of PMP practice questions into Easy, Medium, and Hard categories. The ANN includes two hidden layers for feature transformation and an output layer for classification. The first hidden layer contains 128 neurons, followed by a second hidden layer with 64 neurons, both utilizing the ReLU activation function. The output layer consists of three neurons with a softmax activation function, enabling the model to produce probability distributions for the three difficulty levels.</p>
              <p className="Intro">To enhance the model's input, the text-based questions were processed into numerical representations using BERT embeddings. Each question was converted into a 768-dimensional vector, effectively capturing the contextual meaning of the text. This advanced embedding method ensured that the model could leverage semantic information, improving classification accuracy even with a relatively small dataset. This step was critical to bridge the gap between textual input and numerical modeling.</p>
              <p className="Intro">The ANN was trained using the Adam optimizer, which provides adaptive learning rates for faster convergence. The categorical cross-entropy loss function was used to measure the difference between predicted probabilities and true labels. Accuracy was chosen as the primary metric for model performance evaluation. Training involved 500 epochs with a batch size of 16, which allowed the model to generalize effectively without overfitting on the small dataset.</p>
              <img src="https://firebasestorage.googleapis.com/v0/b/ketofinder-a8370.appspot.com/o/Model01.png?alt=media&token=db60d1ea-8e30-41c6-99a0-0ce2ba1e3597" alt="Model Architecture " className="pic" />
              <img src="https://firebasestorage.googleapis.com/v0/b/ketofinder-a8370.appspot.com/o/Model_02.png?alt=media&token=91ea6e25-f695-4755-8a32-485180781fc4" alt="Model Architecture " className="pic" />


            </div>
          </Cell>

        </Grid>
      </div>

    )
  }
}


export default Analysis