import React, { Component } from 'react'

// React-DML imports
import { Grid, Cell } from "react-mdl";

export class BetaApp extends Component {
  render() {
    return (
      <div>
        <Grid className="HomePageGrid">
          <Cell col={12}>
            <div className="Profile-title-text">

              <h2 className="Profile-subsubtitle-text"><a href="https://forms.gle/zkoeHXwvTU4gT5gh6">Register to be a Beta Tester</a></h2>
              <p className="Intro">We are excited to announce the upcoming beta release of our enhanced PMP Practice App! This exclusive program offers early access to innovative features like difficulty classification and personalized practice recommendations, designed to optimize your PMP exam preparation. As a beta tester, your feedback will play a vital role in shaping the final version of the app. If you're interested in joining, please register through the provided link and become a part of this transformative learning experience. Spaces are limited, so sign up today!</p>
              <p className="Intro"><a href="https://forms.gle/zkoeHXwvTU4gT5gh6">Follow the link</a></p>

            </div>
          </Cell>

        </Grid>
      </div>

    )
  }
}

export default BetaApp