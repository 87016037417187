import React, { Component } from 'react'

// React-DML imports
import { Grid, Cell } from "react-mdl";

export class Conclusion extends Component {
  render() {
    return (
      <div>
        <Grid className="HomePageGrid">
          <Cell col={12}>
            <div className="Profile-title-text">

              <h2 className="Profile-subsubtitle-text">Conclusion</h2>
              <p className="Intro">This project successfully classified PMP practice questions into three difficulty levels: Easy, Medium, and Hard. By leveraging BERT embeddings for feature extraction and an ANN with a carefully designed architecture, the model achieved robust performance, even with a relatively small dataset of 450 questions. The results emphasize the value of using advanced text representation techniques to enhance neural network accuracy. This classification system directly contributes to improving PMP exam preparation by targeting user weaknesses and optimizing study efficiency.</p>
              <p className="Intro">The confusion matrix revealed strong performance in identifying Easy questions, while some overlap persisted between Medium and Hard categories. These findings highlight the nuances in distinguishing questions of close difficulty levels, suggesting opportunities for refinement. The inclusion of adaptive learning paths and readiness prediction can address this challenge by personalizing the learning experience further. These insights validate the project’s approach and provide a roadmap for iterative improvements.</p>
              <p className="Intro">Beyond classification, this system has the potential to transform how PMP candidates approach their exam preparation. The ability to adaptively recommend questions based on a candidate’s performance ensures targeted learning that maximizes time efficiency. The project's methodology and outcomes can also extend to other certification exams or educational contexts, showcasing its versatility and scalability.</p>
              <p className="Intro">Future developments include expanding the dataset by incorporating more questions and refining the classification model for greater accuracy. Additional AI-powered features, such as readiness prediction and dynamic question recommendations, will further personalize the learning process. By integrating user feedback and continuously improving the model, the application can evolve into a comprehensive study tool that caters to diverse learner needs.</p>
              <p className="Intro">A closed <a href="/BetaApp">beta release</a> of the app is planned for January, allowing users to experience the system and provide valuable feedback. This release will serve as a foundation for refining features and ensuring the model's alignment with user expectations. Interested testers can join the beta program through the provided link, marking an exciting milestone for the project's practical application and user engagement.</p>
            </div>
          </Cell>

        </Grid>
      </div>

    )
  }
}

export default Conclusion